<template>
    <ValidationObserver ref="adsDetailsObserver">
        <DetailsForm>
            <template #actions>
                <div
                    class="d-flex justify-content-between notification-details-card__footer mt-1"
                >
                    <div>
                        <b-button
                            variant="primary"
                            @click="acceptCurrentNotificationOrder"
                            >قبول</b-button
                        >
                        <b-button
                            variant="outline-danger"
                            class="mx-2"
                            @click="rejectCurrentNotificationOrder"
                            >رفض</b-button
                        >
                    </div>
                    <div>
                        <b-button
                            variant="outline-danger"
                            class="mx-2"
                            @click="deleteNotificationOrder"
                        >
                            حذف
                        </b-button>
                        <b-button
                            variant="outline-primary"
                            @click="navigateToOrders"
                            >تراجع</b-button
                        >
                    </div>
                </div>
            </template>
        </DetailsForm>
    </ValidationObserver>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import DetailsForm from "../../notifications/components/DetailsForm.vue";

export default {
    components: {
        DetailsForm,
    },
    props: {
        id: String,
        activityId: String,
    },
    computed: {
        ...mapState({
            adsDto: (state) => state.adsAndOffers.adsDto,
        }),
        ...mapGetters([
            "activitesList",
            "adsStatusName",
            "adsIsActive",
            "adsIsExpired",
        ]),
        isActivityOrder() {
            return this.$route.name == "ActivitiyNotificationOrderDetails";
        },
    },
    created() {
        this.fetchNotificationOrderById(this.id);
    },
    beforeDestroy() {
        this.Set_Notification_Dto();
    },
    data: () => ({
        config: {
            allowInput: true,
            mode: "range",
            min: "",
            max: "",
        },
    }),
    methods: {
        ...mapActions([
            "fetchNotificationOrderById",
            "acceptNotificationOrder",
            "rejectNotificationOrder",
            "acceptActivityNotificationOrder",
            "rejectActivityNotificationOrder",
            "deleteRangeNotificationOrder",
        ]),
        ...mapMutations([
            "Set_Notification_Dto",
            "Update_Ads_Image",
            "Update_Ads_Dto_Date",
            "Update_Ads_Dto_Activity_Details",
        ]),
        updateSelctedActivity($event) {
            let activity = this.activitesList.find((item) => item.id == $event);

            this.Update_Ads_Dto_Activity_Details(activity);
        },
        updateAdsDate($event) {
            this.Update_Ads_Dto_Date($event);
        },
        updateAdsFiles($event) {
            this.adsDto.file = $event;
        },
        acceptCurrentNotificationOrder() {
            if (this.isActivityOrder) {
                this.acceptActivityNotificationOrder({
                    id: this.id,
                    activityId: this.activityId,
                });
            } else {
                this.acceptNotificationOrder(this.id);
            }
        },
        rejectCurrentNotificationOrder() {
            if (this.isActivityOrder) {
                this.rejectActivityNotificationOrder({
                    id: this.id,
                    activityId: this.activityId,
                });
            } else {
                this.rejectNotificationOrder(this.id);
            }
        },
        beforeDestroy() {
            this.$refs.adsImages.reset();
        },
        selectedActivity() {
            return this.activitesList.find(
                (el) => el.id == this.adsDto.accountId
            );
        },
        navigateToOrders() {
            if (this.isActivityOrder) {
                this.$router.push(`/admin/activities/${this.activityId}`);
                return;
            }
            this.$router.push("/admin/orders");
        },
        deleteNotificationOrder() {
            this.deleteRangeNotificationOrder([
                { id: this.id, requestTypes: 2 },
            ]);
        },
    },
};
</script>

<style scoped lang="scss">
.ads-order-details-card {
    &__header {
        padding: 0rem !important;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
        }
    }

    &__title {
        font-size: 1.4rem;

        color: #5e5873;
    }

    &__ads-status-title {
        font-size: 1.1rem;

        color: #5e5873;
    }

    &__ads-status {
        background-color: rgba(0, 127, 255, 0.12) !important;
        font-size: 1rem;
        border-radius: 200px;
        font-weight: 700;
        padding: 0.4rem;
        width: 60px;
        &--isActive {
            color: #28c76f !important;
        }

        &--isExpired {
            color: #ff9f43 !important;
        }
    }

    &__footer-button {
        padding: 10px 25px;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 300;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.4px;
    }

    &__update-button {
        color: #ffffff !important;
    }

    &__remove-button {
        background-color: white !important;
        color: #ff0000 !important;
        border-color: #ff0000 !important;
    }

    &__back-button {
        color: #007fff !important;
        border-color: #007fff !important;
    }
    &__delete-button {
        background-color: #edf4f9 !important;
        color: #000000 !important;
    }
}
</style>
